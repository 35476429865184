
export default defineNuxtRouteMiddleware((to, from) => {
  //const authCookie = useCookie('userToken')
  const refreshTokenCookie = useCookie('refreshToken')
  // Se NON LOGGABILE
  if(!refreshTokenCookie.value) {
    // Se in navigazione, redirect a login
    if(!['login', 'reset-password'].includes(to.name)) {
      if(!['logout'].includes(to.name)) {
        console.error('403 Forbidden')
      }
      return navigateTo({ name: 'login' })
    }
    if(['logout'].includes(to.name)) {
      const destination = to.name !== from.name ? from : { name: 'index' }
      return navigateTo(destination)
    }
  } else {
    // Se LOGGATO e in navigazione redirect a index
    if(['login'].includes(to.name)) {
      return navigateTo({ name: 'index' })
    }
  }
})
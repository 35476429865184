import { gsap } from 'gsap'
import { Flip } from 'gsap/Flip'
import { Draggable } from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'

export default defineNuxtPlugin(nuxtApp => {
  if (process.client) {
    gsap.registerPlugin(Flip, Draggable, InertiaPlugin)
  }

  return {
    provide: {
      gsap,
      Flip,
      Draggable
    }
  }
})

<template>
  <div
    v-if="showDeviceAlert"
    class="fixed top-0 left-0 z-50 flex w-full h-full leading-normal bg-black text-grey text-md"
  >
    <div class="container flex flex-col items-center justify-center gap-6 text-center px-gutter">
      <SvgoDesktop class="w-24 text-accent" />
      <span>
        Questa applicazione è disponibile su dispositivi desktop e tablet in visualizzazione
        orizzontale
      </span>
    </div>
  </div>
</template>

<script setup>
const deviceOrientation = ref('')
const viewportHeight = ref(0)
const viewportWidth = ref(0)

// Computed
const showDeviceAlert = computed(
  () => viewportWidth.value < 1024 && viewportHeight.value > viewportWidth.value
)

// Methods
const checkDevice = () => {
  const { innerHeight, innerWidth, screen } = window
  viewportHeight.value = innerHeight
  viewportWidth.value = innerWidth
  // deviceOrientation.value = screen?.orientation?.type || (innerHeight > innerWidth ? 'portrait' : 'landscape-primary')
}

onMounted(() => {
  checkDevice()
  window.addEventListener('resize', checkDevice)
})
onUnmounted(() => {
  window.removeEventListener('resize', checkDevice)
})
</script>

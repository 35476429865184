export const useUsersApi = () => {
  const findOne = async (id, asyncAction = true, idType = 'DATABASE_ID') => {
    let user
    if (asyncAction) {
      const { data } = await useAsyncGql('getUserData', { id, idType })
      user = data?.value?.user
    } else {
      const data = await GqlInstance('getUserData', { id, idType })
      user = data?.user
    }
    return user
  }
  return { findOne }
}

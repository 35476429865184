export const useUserStore = defineStore('user', {
  state: () => ({
    userData: {}
  }),

  getters: {
    userId: state => state.userData?.user?.databaseId || 0,
    userRole: state =>
      state.userData.user?.roles?.nodes.length ? state.userData.user.roles.nodes[0].name : null,
    userCompany: state =>
      state.userData.user.userMeta.company?.edges?.length
        ? state.userData.user.userMeta.company.edges[0].node
        : {},
    userReferent: state =>
      state.userData.user.userMeta.referent?.edges?.length
        ? state.userData.user.userMeta.referent.edges[0].node
        : null,
    excludedBrands: state =>
      state.userData.user.userMeta.excludedBrands?.edges?.length
        ? state.userData.user.userMeta.excludedBrands.edges
        : null
  },

  actions: {
    setUserData(data) {
      this.userData = data
    }
  }
})

<template>
  <div class="flex flex-col items-center justify-center h-full">
    <h1 class="mb-6 text-xl font-bold">
      {{ error?.statusCode === 404 ? 'Pagina non trovata' : 'Oops, qualcosa è andato storto!' }}
    </h1>
    <NuxtLink to="/">
      <ElementsButton label="Torna alla homepage" />
    </NuxtLink>
  </div>
</template>

<script setup>
definePageMeta({
  layout: 'default'
})
const error = useError()
</script>

<style>
body {
  @apply bg-grey-dark text-white text-sm font-sans font-medium h-screen antialiased;
}

#__nuxt {
  @apply h-full;
}
</style>

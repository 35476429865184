export const useLoaderStore = defineStore('loader', {
  state: () => ({
    visible: false,
    label: undefined
  }),

  actions: {
    start(message) {
      this.visible = true
      if (message) this.label = message
    },
    end() {
      this.visible = false
      this.label = undefined
    }
  }
})

import { defineNuxtPlugin } from '#app/nuxt'
import { LazyRowPresentation, LazyRowProject, LazyRowProjectResult, LazyRowProjectSelect, LazyRowQuote, LazyRowQuoteSelect, LazyCheckbox, LazyRadio, LazyRange, LazySvgoAdd, LazySvgoAngleDownBig, LazySvgoAngleDown, LazySvgoAngleLeft, LazySvgoAngleRight, LazySvgoClose, LazySvgoDelete, LazySvgoDesktop, LazySvgoDownload, LazySvgoDuplicate, LazySvgoEditBig, LazySvgoEdit, LazySvgoExport, LazySvgoFiles, LazySvgoFolder, LazySvgoHomepage, LazySvgoMinus, LazySvgoMoodboard, LazySvgoPasswordHidden, LazySvgoPasswordVisibile, LazySvgoQuote, LazySvgoSave, LazySvgoSearch, LazySvgoUndo, LazySvgoUpload, LazySvgoUser, LazyCldImage, LazyCldMediaLibrary, LazyCldOgImage, LazyCldProductGallery, LazyCldUploadButton, LazyCldUploadWidget, LazyCldVideoPlayer } from '#components'
const lazyGlobalComponents = [
  ["RowPresentation", LazyRowPresentation],
["RowProject", LazyRowProject],
["RowProjectResult", LazyRowProjectResult],
["RowProjectSelect", LazyRowProjectSelect],
["RowQuote", LazyRowQuote],
["RowQuoteSelect", LazyRowQuoteSelect],
["Checkbox", LazyCheckbox],
["Radio", LazyRadio],
["Range", LazyRange],
["SvgoAdd", LazySvgoAdd],
["SvgoAngleDownBig", LazySvgoAngleDownBig],
["SvgoAngleDown", LazySvgoAngleDown],
["SvgoAngleLeft", LazySvgoAngleLeft],
["SvgoAngleRight", LazySvgoAngleRight],
["SvgoClose", LazySvgoClose],
["SvgoDelete", LazySvgoDelete],
["SvgoDesktop", LazySvgoDesktop],
["SvgoDownload", LazySvgoDownload],
["SvgoDuplicate", LazySvgoDuplicate],
["SvgoEditBig", LazySvgoEditBig],
["SvgoEdit", LazySvgoEdit],
["SvgoExport", LazySvgoExport],
["SvgoFiles", LazySvgoFiles],
["SvgoFolder", LazySvgoFolder],
["SvgoHomepage", LazySvgoHomepage],
["SvgoMinus", LazySvgoMinus],
["SvgoMoodboard", LazySvgoMoodboard],
["SvgoPasswordHidden", LazySvgoPasswordHidden],
["SvgoPasswordVisibile", LazySvgoPasswordVisibile],
["SvgoQuote", LazySvgoQuote],
["SvgoSave", LazySvgoSave],
["SvgoSearch", LazySvgoSearch],
["SvgoUndo", LazySvgoUndo],
["SvgoUpload", LazySvgoUpload],
["SvgoUser", LazySvgoUser],
["CldImage", LazyCldImage],
["CldMediaLibrary", LazyCldMediaLibrary],
["CldOgImage", LazyCldOgImage],
["CldProductGallery", LazyCldProductGallery],
["CldUploadButton", LazyCldUploadButton],
["CldUploadWidget", LazyCldUploadWidget],
["CldVideoPlayer", LazyCldVideoPlayer],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

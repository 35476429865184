import revive_payload_client_hCE9lSkGUx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I7hZeEwlYy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_m0WvHEQrcP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rqhZcRzc0F from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_eyN6uWTUQK from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_i6KmlUq6vb from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.4.31/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mEoYMZezr4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_f1tWuI57z5 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.2_vue@3.4.31/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_BL70Y2Sgaj from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_vue@3.4.31/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_Ux6NnOZA3p from "/opt/build/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.35_graphql-tag@2.12.6/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_we1oWlDpOe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_vite@5.3.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_auth_pq8n4g3Sus from "/opt/build/repo/plugins/01.auth.js";
import gsap_client_Z6XKCRtOCT from "/opt/build/repo/plugins/gsap.client.js";
import pdfmake_o0ig8POQiq from "/opt/build/repo/plugins/pdfmake.js";
import presentation_nz8rlOU3yT from "/opt/build/repo/plugins/presentation.js";
import sentry_PZVGm6LH3G from "/opt/build/repo/plugins/sentry.js";
export default [
  revive_payload_client_hCE9lSkGUx,
  unhead_I7hZeEwlYy,
  router_m0WvHEQrcP,
  payload_client_rqhZcRzc0F,
  check_outdated_build_client_eyN6uWTUQK,
  plugin_vue3_i6KmlUq6vb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mEoYMZezr4,
  plugin_f1tWuI57z5,
  plugin_BL70Y2Sgaj,
  plugin_Ux6NnOZA3p,
  chunk_reload_client_we1oWlDpOe,
  _01_auth_pq8n4g3Sus,
  gsap_client_Z6XKCRtOCT,
  pdfmake_o0ig8POQiq,
  presentation_nz8rlOU3yT,
  sentry_PZVGm6LH3G
]
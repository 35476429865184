import pdfMake from "@sirfull/pdfmake/build/pdfmake";
import { chunk, flattenDeep, flatten, pickBy, uniqBy } from 'lodash-es'
import { format, parseISO } from 'date-fns'

pdfMake.fonts = {
  Manrope: {
    normal: 'https://configuratore.mad051.it/fonts/manrope/manrope-regular.otf',
    bold: 'https://configuratore.mad051.it/fonts/manrope/manrope-bold.otf',
    bolditalics: 'https://configuratore.mad051.it/fonts/manrope/manrope-extrabold.otf'
  }
}

const utils = {
  getAttributes(lineItem, product) {
    return product?.attributes?.edges?.length ? product.attributes.edges.map(({ node }) => {
      let relatedAttribute
      let relatedTerm = null
      if (!node.terms || !node.options) {
        if (
          lineItem.product.attributes?.edges?.length &&
          lineItem.product.attributes.edges.some(({ node: attribute }) => {
            return attribute.terms?.nodes?.length && node.value
            ? attribute.terms.nodes.some(term => term.slug === node.value)
            : false
          })
          ) {
            lineItem.product.attributes.edges.map(({ node: attribute }) => {
              attribute.terms.nodes.map(term => {
              relatedTerm =
                term.slug === node.value
                  ? term?.name ? term.name : node.value
                  : relatedTerm
              return term
            })
            return attribute
          })
        }
      }
  
      if (node.terms?.nodes?.length) {
        relatedAttribute = node.terms.nodes[0].name
      } else if(node.options?.length) {
        relatedAttribute = node.options[0].toUpperCase() 
      } else {
        relatedAttribute = relatedTerm || node.value
      }
      return relatedAttribute
    }).join(' | ') : ''
  },
  generateFooter (content) {
    return {
      layout: 'noBorders',
      absolutePosition: {
        x: 0, y: 842 - 35
      },
      table: {
        widths: [ '*' ],
        body: [
          [ { text: content, alignment: 'right', margin: [35, 0],  fontSize: 7 } ]
        ]
      }
    }
  },
  generateHeaderLogos () {
    return {
      layout: 'noBorders',
      absolutePosition: {
        x: 0, y: 80
      },
      table: {
        widths: [ '*', 180, 120 ],
        body: [
          [ 
            { text: '' },
            {
              margin: [0,0,55,0],
              stack: [
                { text: 'Appartenente alla rete', margin: [0,0,0,10], color: 'white' },
                { image: 'logoRistrutturo', width: 87, height: 45 }
              ]
            },
            {
              margin: [0,0,55,0],
              stack: [
                { text: 'POWERED BY', margin: [0,0,0,10], color: 'white' },
                { image: 'logoMAD', width: 45, height: 45 }
              ]
            }
          ]
        ]
      }
    }
  },
  generateFooterLogos () {
    return {
      layout: 'noBorders',
      absolutePosition: {
        x: 0, y: 842 - 120
      },
      table: {
        widths: [ '*', 180, 120 ],
        body: [
          [ 
            { text: '' },
            {
              margin: [0,0,55,0],
              stack: [
                { text: 'Appartenente alla rete', margin: [0,0,0,10] },
                { image: 'logoRistrutturo', width: 77, height: 40 }
              ]
            },
            {
              margin: [0,0,55,0],
              stack: [
                { text: 'POWERED BY', margin: [0,0,0,10] },
                { image: 'logoMAD', width: 40, height: 40 }
              ]
            }
          ]
        ]
      }
    }
  },
  generateQuoteRow(data) {
    const product = data.variation?.node || data.variation || data.product
    const attributes = utils.getAttributes(data, product)
    return [
      { 
        stack: [
          data.product.name,
          { text: attributes, verticalAlign: 'middle' }
        ],
        fontSize: 9,
        margin: [0, 5],
        width: '70%'
      },
      { verticalAlign: 'middle', text: data.product?.metaData?.um ? data.product.metaData.um.toLowerCase().replaceAll('m2', 'm²') : '', fontSize: 9, margin: [0, 5], width: '10%'},
      { verticalAlign: 'middle', text: data.product?.metaData?.mq ? (data.quantity * data.product.metaData.mq).toFixed(2) : data.quantity, fontSize: 9, margin: [0, 5], width: '5%', alignment: 'right'},
      { verticalAlign: 'middle', text: data.total.toFixed(2), fontSize: 9, margin: [0, 5], width: '10%', alignment: 'right' },
      { verticalAlign: 'middle', text: data.metaData.vat.value, fontSize: 9, margin: [0, 5], width: '5%', alignment: 'right' }
    ]
  },
  generateQuotePageTable(chunk = [], userData, pageBreak = false) {

    const companyName = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.name || '') : ''
    const companyAddress = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.companyDetails.address || '') : ''
    const companyPhone = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.companyDetails.phone || '') : ''
    const companyEmail = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.companyDetails.email || '') : ''

    return [{
      pageBreak: !pageBreak ? '' : 'before',
      layout: {
        hLineWidth: function (i, node) {
          return 1
        },
        vLineWidth: function (i) {
          return 0
        },
        hLineColor: function (i) {
          return '#aaa'
        },
        paddingLeft: function (i) {
          return 8
        },
        paddingRight: function (i, node) {
          return 8
        },
        paddingBottom: function (i) {
          return 5
        },
        paddingTop: function (i, node) {
          return 5
        }
      },
      margin: [ 40, 40, 40, 60 ],
      table: {
        widths: ['*', 30, 50, 80, 30],
        headerRows: 1,
        body: [
          [
            { text: 'Descrizione', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '70%' },
            { text: 'UM', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '10%' },
            { text: 'Quantità', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '5%', alignment: 'right' },
            { text: 'Importo', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '10%', alignment: 'right' },
            { text: 'IVA', bold: true, fontSize: 9, fillColor: '#ededed', margin: [0, 5], width: '5%', alignment: 'right' }
          ],
          ...chunk.map(i => utils.generateQuoteRow(i))
        ]
      }
    },
    {
      absolutePosition: {
        x: 40, y: 1110
      },
      layout: 'noBorders',
      table: {
        widths: [242, 242, 242],
        body: [
          [
            {
              margin: [ 20, 0, 0, 0],
              stack: [
                companyName,
                companyAddress
              ],
              fontSize: 8
            },
            {
              stack: [
                `Telefono ${companyPhone}`,
                `Email ${companyEmail}`
              ],
              fontSize: 8
            },
            {
              layout: 'noBorders',
              table: {
                widths: [100, 60, 60],
                body: [
                  [
                    { text: '' },
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'MEMBRO', fontSize: 6 }],
                          [ { image: 'logoRistrutturo', width: 53, height: 30 } ],
                        ]
                      }
                    },
                    { 
                      align: 'right',
                      layout: 'noBorders',
                      table: {
                        widths: [60],
                        body: [
                          [{ text: 'POWERED BY', fontSize: 6 }],
                          [ { image: 'logoMAD', width: 30, height: 30 } ],
                        ]
                      }
                    },
                  ],
                ]
              }
            }
          ]
        ]
      }
    }]
  },
  generateQuoteSummary(data, fixed) {
    return {
      // margin: [ 0, tabLength <= 8 ? 0 : 50],
      absolutePosition: fixed ? { x: 0, y: 850 } : false,
      pageBreak: fixed ? '' : 'before',
      margin: [ 40, 40, 40, 60 ],
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i) {
          return 0
        },
        paddingLeft: function (i) {
          return 35
        },
        paddingRight: function (i, node) {
          return 35
        },
        paddingBottom: function (i) {
          return 0
        },
        paddingTop: function (i, node) {
          return 0
        }
      },
      table: {
        widths: ['*'],
        margin: 35,
        body: [
          [
            {
              layout: {
                hLineWidth: function (i, node) {
                  return 1
                },
                vLineWidth: function (i) {
                  return 0
                },
                hLineColor: function (i) {
                  return '#aaa'
                },
                fillColor: function (i, node) {
                  return '#ededed'
                },
                paddingLeft: function (i) {
                  return 20
                },
                paddingRight: function (i, node) {
                  return 20
                },
                paddingBottom: function (i) {
                  return 12
                },
                paddingTop: function (i, node) {
                  return 12
                }
              },
              table: {
                widths: ['60%', '40%'],
                body: [
                  [
                    {
                      fillColor: 'white',
                      ul: [
                        /* furnishings section begin */
                        { 
                          listType: 'none',
                          columns: data.furnishings?.length ? [ 
                            { text: 'Arredi', bold: true, fontSize: 9, width: '60%' }, 
                            { text: 'Importo', bold: true, fontSize: 9, width: '20%' }, 
                            { text: 'IVA', bold: true, fontSize: 9, width: '20%' }
                          ] : []
                        },
                      ...(data.furnishings || []).map(furnish => {
                        const furnishPrice = furnish.total && typeof furnish.total !== 'string' ? furnish.total.toFixed(2) : '0.00'
                          return { 
                            listType: 'none',
                            columns: [ {
                              text: furnish.metaData?.furnishName?.value ? furnish.metaData.furnishName.value : furnish.product.name,
                              fontSize: 9,
                              margin: [0, 2],
                              widh: '60%'
                            },
                            { text: `€ ${furnishPrice}`, fontSize: 9, margin: [0, 2], width: '20%'},
                            { text: furnish.metaData.vat.value, fontSize: 9, margin: [0, 2], width: '20%'},
                          ]
                        }
                      }),
                      /* furnishings section end */
                        /* services section begin */
                        { 
                          listType: 'none',
                          columns: data.services.length ? [ 
                            { text: 'Servizi / Maggiorazioni', bold: true, fontSize: 9, width: '60%' }, 
                            { text: 'Importo', bold: true, fontSize: 9, width: '20%' }, 
                            { text: 'IVA', bold: true, fontSize: 9, width: '20%' }
                          ] : []
                        },
                      ...data.services.map(service => {
                        const servicePrice = service.total && typeof service.total !== 'string' ? service.total.toFixed(2) : '0.00'
                          return { 
                            listType: 'none',
                            columns: [ {
                              text: service.metaData?.serviceName?.value ? service.metaData.serviceName.value : service.product.name,
                              fontSize: 9,
                              margin: [0, 2],
                              widh: '60%'
                            },
                            { text: `€ ${servicePrice}`, fontSize: 9, margin: [0, 2], width: '20%'},
                            { text: service.metaData.vat.value, fontSize: 9, margin: [0, 2], width: '20%'},
                          ]
                        }
                      })
                      /* services section end */
                    ]
                  },
                  {
                    verticalAlign: 'middle',
                    ul: [
                      {
                        listType: 'none',
                        margin: [ 50, 0, 0, 0],
                        columns: [
                          { text: 'Totale', fontSize: 11 }, { text: data.subtotal ? '€' + data.subtotal : 0, fontSize: 11, alignment: 'right' }
                        ],
                      },
                      {
                        listType: 'none',
                        margin: [ 50, 0, 0, 0],
                        columns: [
                          { text: 'Totale IVA', fontSize: 11 }, { text: data.taxes ? '€' + data.taxes : 0, fontSize: 11, alignment: 'right' }
                        ],
                      },
                      {
                        listType: 'none',
                        text: data.total ? '€' + data.total : 0,
                        fontSize: 20,
                        bold: true,
                        alignment: 'right',
                      }
                    ]
                  }
                ]
              ]
              }
            }
          ]
        ] 
      }
    }
  }
}

const lib = {
  generateCover(data) {
    return [
      {
        absolutePosition: {
          x: 35, y: 35
        },
        image: 'cover',
        width: 1120,
        height: 777,
        cover: true
      },
      {
        absolutePosition: {
          x: 35, y: 35
        },
        svg: '<svg xmlns="http://www.w3.org/2000/svg"><rect width="1120" height="777" fill="black" fill-opacity="0.5"/></svg>',
        fit: [1120, 777]
      },
      utils.generateHeaderLogos(),
      {
        margin: 35,
        layout: 'noBorders',
        table: {
          widths: [ '*' ],
          heights: 841.89 - 70,
          body: [
            [
              {
                layout: 'noBorders',
                table: {
                  widths: [ '*', 20, '40%' ],
                  heights: [160, 450, 150],
                  body: [
                    [ 
                      { text: '' },
                      { text: '' },
                      { text: '' }
                    ],
                    [ 
                      { text: '' },
                      { text: '' },
                      { text: data.projectName , fontSize: 42, bold: true, verticalAlign: 'middle', color: 'white' }
                    ],
                    [ 
                      { image: 'companyLogo', height: 100, width: 200, margin: [50, -45, 0, 0],  verticalAlign: 'middle', fit: [100, 200] },
                      { text: '' },
                      { 
                        verticalAlign: 'middle',
                        ul: [
                        { text: 'Data: ' + format((new Date()), 'dd/MM/yyyy'), listType: 'none', color: 'white' },
                        { text: `Creato da: ${(data.firstName || '')} ${(data.lastName || '')}`, listType: 'none', color: 'white' }
                      ] }
                    ]
                  ]
                }
              }
            ]
          ]
        }
      } 
    ]
  },
  generateSectionPage(data) {
    return {
      margins: 0,
      pageOrientation: 'landscape',
      pageBreak: 'before',
      layout: 'noBorders',
      table: {
        widths: [ 1190 ],
        heights: 840,
        body: [
          [{ 
            fillColor: '#333',
            layout: 'noBorders',
            table: {
              widths: [ '*', 300 ],
              heights: [250, '*'],
              body: [
                [[ {text: ''} ], { text: data.type, color: '#fff', alignment: 'right', margin: [35, 60], bold: true }],
                [[ {text: ''} ], {
                  stack: [
                    { text: data.title, color: '#fff', alignment: 'left', margin: [0, 0, 35, 0], bold: true },
                    { text: data.text, color: '#fff', alignment: 'left', margin: [0, 0, 35, 0] }
                  ]
                }]
              ]
            }
          }]
        ]
      }
    } 
  },
  generateTemplateA(data) {
    return [
      {
        pageBreak: 'before',
        margin: [70, 25, 35, 35],
        layout: 'noBorders',
        table: {
          widths: [ '*' ],
          heights: ['*', '*', 675],
          body: [
            [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
            [ { text: 'PROGETTO', bold: true, margin: [0, 0, 0, 20] } ],
            [ { image: data.templateImages['image'], width: 1081, height: 675, cover: true } ]
          ]
        }
      },
      utils.generateFooter(data.text)
    ]
  },
  generateTemplateB(data) {
    return [{
      pageBreak: 'before',
      margin: [60, 25, 35, 45],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: ['*', '*', 675],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'PROGETTO', bold: true, margin: [0, 0, 0, 20] } ],
          [ {
            margin: [60, 0, 0, 0],
            layout: 'noBorders',
            table: {
              widths: [ 375 , 1, '*'],
              heights: 675,
              body: [
                [ 
                  { image: data.templateImages['image'], width: 375, height: 675, cover: true }, 
                  { text: ''}, 
                  { image: data.templateImages['image2'], width: 638, height: 675, cover: true }
                ]
              ]
            }
          }]
        ]
      }
    },
    utils.generateFooter(data.text)] 
  },
  generateTemplateC(data) {
    return [{
      pageBreak: 'before',
      margin: [60, 25, 35, 35],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: ['*', '*', 445],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'PROGETTO', bold: true, margin: [0, 0, 0, 20] } ],
          [ {
            layout: 'noBorders',
            table: {
              widths: [ '*' , 1, 320 ],
              heights: 675,
              body: [
                [ 
                  { image: data.templateImages['image'], height: 675, width: 773, cover: true },
                  { text: ''},
                  { image: data.templateImages['image2'], height: 337, width: 320, margin: [ 0, 337, 0, 0 ], cover: true }
                ]
              ]
            }
          }]
        ]
      }
    },
    utils.generateFooter(data.text)] 
  },
  generateTemplateD(data) {
    return [{
      pageBreak: 'before',
      margin: [60, 25, 35, 35],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: ['*', '*', 675],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'PROGETTO', bold: true, margin: [0, 0, 0, 20] } ],
          [ {
            layout: 'noBorders',
            table: {
              widths: [ '33%', 396, '33%' ],
              heights: 675,
              body: [
                [ 
                  { text: ''}, 
                  { image: data.templateImages['image'], height: 675, width: 396, cover: true},
                  { text: ''}
                ]
              ]
            }
          }]
        ]
      }
    }, utils.generateFooter(data.text)]
  },
  generateTemplateE(data) {
    return [{
      pageBreak: 'before',
      margin: [90, 25, 35, 35],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: ['*', '*', 605, '*'],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'PROGETTO', bold: true, margin: [0, 0, 0, 70] } ],
          [ {
            layout: 'noBorders',
            table: {
              widths: [ '*', 892, '*' ],
              heights: 525,
              body: [
                [ 
                  { text: ''}, 
                  { image: data.templateImages['image'], height: 525, width: 892, cover: true},
                  { text: ''}
                ]
              ]
            }
          }]
        ]
      }
    }, utils.generateFooter(data.text)] 
  },
  generateTemplateG(data) {
    return [{
      pageBreak: 'before',
      margin: 0,
      layout: 'noBorders',
      table: {
        widths: [ 1190 ],
        heights: ['*', '*', 675],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 25, 25, 20]  } ],
          [ { text: 'MOOD', bold: true, margin: [60, 0, 0, 20] } ],
          [ { 
              layout: 'noBorders',
              table: {
                widths: [396, 1, 396, 1, 396],
                heights: 675,
                body: [
                  [
                    { image: data.templateImages['image'], height: 675, width: 396, cover: true},
                    {text: ''}, 
                    { image: data.templateImages['image2'], height: 675, width: 396, cover: true},
                    {text: ''}, 
                    { image: data.templateImages['image3'], height: 675, width: 396, cover: true},
                  ]
                ]

          }} ]
        ]
      }
    }, 
    utils.generateFooter(data.text)
    ]
  },
  generateTemplateH(data) {
    return [{
      pageBreak: 'before',
      margin: [60, 25, 35, 35],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: ['*', '*', 675],
        body: [
          [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'MOOD', bold: true, margin: [0, 0, 0, 20] } ],
          [ {
            margin: [60, 0, 0, 0],
            layout: 'noBorders',
            table: {
              widths: [ 764 , 1, 250],
              heights: 675,
              body: [
                [ 
                  { image: data.templateImages['image'], height: 675, width: 764, cover: true},
                  { text: ''}, 
                  { image: data.templateImages['image2'], height: 675, width: 250, cover: true},
                ]
              ]
            }
          }]
        ]
      }
    }, utils.generateFooter(data.text)] 
  },
  generateTemplateI(data) {
    const room = data.room && typeof data.room === 'string' ? data.room.toUpperCase() : ''
    return [
      {
        pageBreak: 'before',
        margin: [70, 25, 35, 35],
        layout: 'noBorders',
        table: {
          widths: [ '*' ],
          heights: ['*', '*', 675],
          body: [
            [ { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
            [ { text: `MOODBOARD ${room}`, bold: true, margin: [0, 0, 0, 20] } ],
            [ { image: data.templateImages['image'], width: 1081, height: 675, cover: true } ]
          ]
        }
      },
      utils.generateFooter(data.text)
    ]
  },
  generateTemplateMoodboard(data) {
    const room = data.room && typeof data.room === 'string' ? data.room.toUpperCase() : ''
    return [
      {
        pageBreak: 'before',
        margin: [45, 25, 25, 25],
        layout: 'noBorders',
        table: {
          widths: [ '*' ],
          heights: ['*', '*', 640],
          body: [
            [ { text: data.projectName , color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
            [ { text: `MOODBOARD ${room}`, bold: true, margin: [0, 0, 0, 20] } ],
            [ {
              layout: 'noBorders',
              table: {
                widths: [ 122, '*', 122 ],
                heights: 600,
                body: [
                  [ { text: ''}, { image: data.image, height: 600, width: 875 }, { text: '' } ]
                ]
              }
            }]
          ]
        }
      },
      // utils.generateFooter('Mood | allineamento a dx')
    ]
  },
  generateProducts(data) {
    let prods = data.products.map(prod => {
      const product = prod.variation?.node || prod.variation || prod.product
      return [
      { image: `product-${prod.product.databaseId}`, height: 220, width: 220, cover: true }, 
      { ul: [
        { text: prod.product.brand.nodes.map(i => i.name).join(' | '), listType: 'none' },
        { text: prod.product.productCategories.edges.map(edge => edge.node.name).join(' | '), listType: 'none' },
        { text: utils.getAttributes(prod, product), listType: 'none' },

      ], verticalAlign: 'middle', margin: [0, 0, 20, 0]},
    ]})

    if (prods.length % 2 == 1) {
      prods.push([{text:''}, {text:''}])
    }

    prods = chunk(flatten(prods), 4)

    return {
      pageBreak: 'before',
      margin: [60, 25, 35, 43],
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        body: [
          [ { text: data.projectName , color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
          [ { text: 'DETTAGLIO PRODOTTI', bold: true, margin: [0, 0, 0, 20] } ],
          [
            {
              table: {
                widths: ['20%', '30%', '20%', '30%'],
                heights: 220,
                body: [
                  ...prods
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return 10
                },
                vLineWidth: function (i, node) {
                  return 0
                },
                hLineColor: function (i, node) {
                  return 'white'
                }
              }
            }
          ]
        ]
      }
    } 
  },
  generateQuote(data) {
    const { orderData, userData } = data

    let chunks = chunk([...orderData.mats, ...orderData.prods], 18)
    const fixed = chunks.length ? chunks[chunks.length - 1].length < 18 : false
    return [
    {
      pageBreak: 'before',
      pageOrientation: 'portrait',
      margin: [ 40, 40, 40, 60 ],
      layout: 'noBorders',
      table: {
        widths: [150, '*', 250],
        body: [
          [
            {
              image: 'companyLogo',
              fit: [150, 150]
            },
            {
              text: ''
            },
            {
              alignment: 'left',
              margin: [ 0, 0, 35, 0 ],
              stack: [
                { text: 'PREVENTIVO', bold: true, fontSize: 20 },
                { text: data.projectName, bold: true, fontSize: 9, margin: [0, 0, 0, 5] },
                { text: orderData.quote?.modified ? 'Data: ' + format(parseISO(orderData.quote?.modified), 'dd/MM/yyyy') : '', fontSize: 9 },
                { 
                  text: userData?.firstName || userData?.lastName
                  ? `Creato da: ${(userData.firstName || '')} ${(userData.lastName || '')}`
                  : '',
                  fontSize: 9
                }
              ]
            }
          ]
        ]
      }
    },
    ...utils.generateQuotePageTable(chunks[0], userData),
    ...chunks.slice(1).map(chunk => utils.generateQuotePageTable(chunk, userData, true)),
    utils.generateQuoteSummary(orderData, fixed),
    ]
  },
  generateQuoteSummaryPage(data) {
    const { ordersData } = data

    let materials = 0
    let products = 0
    let services = 0
    let furnishings = 0

    for (const orderData of ordersData) {
      for (const mats of orderData.mats) {
        materials+= parseFloat(mats.subtotal)
      }
      for (const product of orderData.prods) {
        products+= parseFloat(product.subtotal)
      }
      for (const service of orderData.services) {
        services+= parseFloat(service.subtotal)
      }
      for (const furnish of orderData.furnishings || []) {
        furnishings+= parseFloat(furnish.subtotal)
      }
    }


    const total = ordersData.map(o => parseFloat(o.total)).reduce((a, b) => a + b, 0)

    return {
      pageBreak: 'before',
      margin: 35,
      layout: 'noBorders',
      table: {
        widths: [ '*' ],
        heights: 841.89 - 70,
        body: [
          [
            {
              layout: 'noBorders',
              table: {
                widths: [ '*', 20, '40%' ],
                heights: [160, 450, 150],
                body: [
                  [ 
                    { text: '' },
                    { text: '' },
                    {
                      ul: [
                        { text: 'PREVENTIVO', color: '#ccc', bold: true,  alignment: 'right', margin: [0,0,0, 20], listType: 'none' },
                        { text: data.projectName, color: '#ccc', bold: true,  alignment: 'right', listType: 'none' }
                      ]
                    }
                  ],
                  [ 
                    { text: '' },
                    { text: '' },
                    { ul: [
                      { text: `Superfici: ${materials.toFixed(2)} €`, listType: 'none' },
                      { text: `Prodotti: ${products.toFixed(2)} €`, listType: 'none' },
                      { text: `Arredi: ${furnishings.toFixed(2)} €`, listType: 'none' },
                      { text: `Servizi: ${services.toFixed(2)} €`, listType: 'none' },
                      { text: 'Totale:', listType: 'none', fontSize: 38, bold: true, margin: [0, 20, 0, 0] },
                      { text: total.toFixed(2) + '€', listType: 'none', fontSize: 38 },
                    ], verticalAlign: 'middle', alignment: 'right' }
                  ],
                  [ 
                    { text: '' },
                    { text: '' },
                    { text: '' },
                  ]
                ]
              }
            }
          ]
        ]
      }
    }
  },
  generateFullPhoto(data) {
    return {
      margins: 0,
      pageOrientation: 'landscape',
      pageBreak: 'before',
      layout: 'noBorders',
      table: {
        widths: [ 1190 ],
        heights: 840,
        body: [
          [{ 
            image: data.image,
            width: 1190,
            height: 842,
            cover:true
          }]
        ]
      }
    } 
  },
  generateLastPage(data) {
    const companyName = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.name || '') : ''
    const companyAddress = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.address || '') : ''
    const companyPhone = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.phone || '') : ''
    const companyEmail = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.email || '') : ''
    const companyDescription = data?.userData?.userMeta?.company?.edges?.length ? (data.userData.userMeta.company.edges[0].node.companyDetails.description || '') : ''

    return [
      {
        pageBreak: 'before',
        pageOrientation: 'landscape',
        margin: [35, 25, 25, 35],
        layout: 'noBorders',
        table: {
          widths: [ '*' ],
          heights: ['*', 675],
          body: [
            [ { text: companyName, color: '#ccc', bold: true,  alignment: 'right', margin: [0, 0, 0, 20]  } ],
            [ 
              {
                // layout: 'noBorders',
                layout: {
                  paddingLeft: function (i) {
                    return 0
                  },
                  paddingRight: function (i, node) {
                    return 0
                  },
                  hLineWidth: function (i, node) {
                    return 0
                  },
                  vLineWidth: function (i) {
                    return 0
                  }
                },
                margin: [5, 0, 0, 0],
                table: {
                  widths: ['*', 25, '*'],
                  heights: [335, 15,  335],
                  body: [
                    [
                      [{ text: '' }],
                      [{ text: '' }],
                      [{ 
                        // margin: 35,
                        alignment: 'right',
                        layout: {
                          paddingLeft: function (i) {
                            return 0
                          },
                          paddingRight: function (i, node) {
                            return 0
                          },
                          hLineWidth: function (i, node) {
                            return 0
                          },
                          vLineWidth: function (i) {
                            return 0
                          }
                        },
                        table: {
                          widths: ['*'],
                          heights: 335,
                          body: [
                            [ { image: 'companyImage', height: 300, width: 520, cover: { width: 520, height: 300 } } ]
                          ]
                      }}],
                    ],
                    [
                      [{ text: '' }],
                      [{ text: '' }],
                      [{ text: '' }],
                    ],
                    [
                      [{ stack: [
                        { text: companyName },
                        { text: companyAddress, margin: [ 0, 0, 0, 20] },
                        { text: 'Telefono' },
                        { text: companyPhone },
                        { text: 'Email: ' + companyEmail, margin: [ 0, 0, 0, 30] },
                        { image: 'companyLogo', margin: [0, 60, 0, 0], height: 130, width: 130, fit: [130, 130] }
                      ] }],
                      [{ text: '' }],
                      [{ 
                          alignment: 'right',
                          stack: [
                          { text: companyDescription }
                        ]
                      }]
                    ],
                  ]
                }
              }
            ]
          ]
        }
      },
      utils.generateFooterLogos()
    ]
  }
}

const templateMappings = {
  cover: 'generateCover',
  page_1: 'generateSectionPage',
  fullscreen_image: 'generateFullScreenImage',
  last_page: 'generateLastPage',
  'p-template-a': 'generateTemplateA',
  'p-template-b': 'generateTemplateB',
  'p-template-c': 'generateTemplateC',
  'p-template-d': 'generateTemplateD',
  'p-template-e': 'generateTemplateE',
  'p-template-f': 'generateTemplateF',
  'm-template-a': 'generateTemplateG',
  'm-template-b': 'generateTemplateH',
  'm-template-c': 'generateTemplateI',
  moodboard: 'generateTemplateMoodboard',
  products: 'generateProducts',
  customProducts: 'generatecustomProducts',
  quote: 'generateQuote',
  quoteSummary: 'generateQuoteSummaryPage',
  fullPhoto: 'generateFullPhoto',
  lastPage: 'generateLastPage'
}
  

const pdfPresentation = (presentationData) => {

  const { id, userData, presentation, ordersData, projectTitle } = presentationData
  const projectName = projectTitle || ''
  const document = []
  const images = {}
  images.companyLogo = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.featuredImage?.node.sourceCdnUrl || 'https://configuratore.mad051.it/images/empty.png') : 'https://configuratore.mad051.it/images/empty.png'
  images.companyImage = userData?.userMeta?.company?.edges?.length ? (userData.userMeta.company.edges[0].node.companyDetails.companyImage?.node?.sourceCdnUrl || 'https://configuratore.mad051.it/images/empty.png') : 'https://configuratore.mad051.it/images/empty.png'
  images.cover = presentation.cover.image || 'https://configuratore.mad051.it/images/empty.png'

  let allProducts = []

  ordersData.map(od => {
    allProducts = [...allProducts, ...od.mats, ...od.prods]
  })

  allProducts = uniqBy(allProducts, 'product.databaseId')

  let presentationDoc = [
    {
      type: 'cover',
      data: { projectName, ...userData}
    },
    {
      type: 'page_1',
      data: { type: 'PROGETTO', ...presentation.project.page_1 }
    },
    Object.values(presentation.project.pages).map((page, i) => {
      // const _images = Object.values(pickBy(Object.keys(page), (field) => field.startsWith('image') || field.startsWith('img')))
      const _images = Object.values(pickBy(Object.keys(page), field => field.startsWith('image') && !field.includes('SuggestedSizes')))
      const values = _images.map(img => page[img])
      if (values.every(v => v === null || typeof v === 'undefined')) {
        return null
      }
      const templateImages = {}
      for (const _image of _images) {
        images['p-' + page.template + '-' +  _image +'-' + i] = page[_image] ? page[_image] : 'https://configuratore.mad051.it/images/empty.png'
        templateImages[_image] = 'p-' + page.template + '-' +  _image +'-' + i
      }

      return {
        type: 'p-' + page.template,
        data: { ...page, projectName, templateImages }
      }

    }),
    presentation.moodboards.map((moodboard, y) => {
      images[`mood-${y}`] = moodboard.mainImage || 'https://configuratore.mad051.it/images/empty.png'
      return  [
        {
          type: 'page_1',
          data: { type: 'MOOD', ...moodboard.page_1, projectName }
        },
        {
          type: 'moodboard',
          data: { type: 'moodboard', image: `mood-${y}`, room: moodboard.room, projectName }
        },
        ...Object.values(moodboard.pages).map((page, i) => {

          // const _images = Object.values(pickBy(Object.keys(page), (field) => field.startsWith('image') || field.startsWith('img')))
          const _images = Object.values( pickBy(Object.keys(page), field => field.startsWith('image') && !field.includes('SuggestedSizes')) )
          const values = _images.map(img => page[img])
          if (values.every(v => v === null || typeof v === 'undefined')) {
            return null
          }
          const templateImages = {}
          for (const _image of _images) {
            images['m-' + page.template + '-' +  _image + '-'+ y +'-' + i] = page[_image] ? page[_image] : 'https://configuratore.mad051.it/images/empty.png'
            templateImages[_image] = 'm-' + page.template + '-' +  _image + '-'+ y +'-' + i
          }
          return {
            type: 'm-' + page.template,
            data: { ...page, templateImages, projectName }
          }
        })
      ]
    }),

    presentation.orders && (presentation.orders.details || presentation.orders.aggregated_costs) ?
    {
      type: 'page_1',
      data: { type: 'MATERIALI E ARREDO', ...presentation.products.page_1 }
    } : null,

    
    
    chunk(allProducts, 6).map(chunk => {
      chunk.map(async prod => {
        if (prod.product.metaData.material) {
          images[`product-${prod.product.databaseId}`] = prod?.product?.image?.sourceCdnUrl || 'https://configuratore.mad051.it/images/empty.png'
        } else {
          let image = prod?.product?.image?.sourceCdnUrl
          let imageResponse = { ok: false } // image ? await fetch(`${image.replace('/upload/', '/upload/w_220,h_220,b_gen_fill,c_auto_pad,g_auto/')}`, { method: "HEAD" }) : false
          image = image
            ? imageResponse?.ok
              ? image.replace('/upload/', '/upload/w_220,h_220,b_gen_fill,c_auto_pad,g_auto/') 
              : image.replace('/upload/', '/upload/w_220,h_220,c_auto_pad,g_auto/')
            : false
          images[`product-${prod.product.databaseId}`] = image || 'https://configuratore.mad051.it/images/empty.png'
        }    
      })

      return {
        type: 'products',
        data: { products: chunk, projectName }
      }
    }),

    ordersData.map(orderData => {
      return [
        {
          type: 'page_1',
          data: { type: 'PREVENTIVI', ...presentation.orders.page_1 }
        },
        presentation.orders && presentation.orders.details ? { type: 'quote', data: { projectName, orderData, userData } } : null
      ]
    }),
    presentation.orders && presentation.orders.aggregated_costs ? { type: 'quoteSummary', data: { projectName, ordersData } } : null,
    presentation.orders && presentation.orders.details  ? Object.values(presentation.orders.pages).map((page, i) => {
      if (!page.image) {
        return null
      }
      images['full-' + i] = page.image
      return {
        type: 'fullPhoto',
        data: { image: 'full-' + i }
      }
    }) : null,
    {
      type: 'lastPage',
      data: { userData }
    }
  ]

  presentationDoc = flattenDeep(presentationDoc.filter(_ => _)).filter(_ => _)

  for (const page of presentationDoc) {
    const fn = lib[templateMappings[page.type]]
    document.push(fn(page.data))
  }

  const basePDF = {
    defaultStyle: {
      font: 'Manrope',
      fontSize: 10
    },
    pageSize: 'A3',
    pageOrientation: 'landscape',
    pageMargins: 0,
    images: {
      empty: 'https://configuratore.mad051.it/images/empty.png',
      gPla: 'https://placehold.co/600x400.png',
      placeholder: 'https://placehold.co/600x400.png',
      // placeholder: 'https://picsum.photos/600/400',
      logoRistrutturo: 'https://configuratore.mad051.it/images/irea_black.png',
      logoMAD: 'https://configuratore.mad051.it/mad051-logo-dark.png',
      ...images
      },
    content: document
  }

  pdfMake.createPdf(basePDF).download(`presentazione-${id}.pdf`)
}

export default defineNuxtPlugin(nuxtApp => {
  return {
    provide: { pdfPresentation }
  }
})
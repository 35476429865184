export const useNotificationStore = defineStore('notification', {
  state: () => ({
    items: []
  }),

  actions: {
    push({ icon, content }) {
      this.items.push({ icon, content })
      setTimeout(() => {
        this.items.shift()
      }, 6000)
    }
  }
})

import { default as accountiPk3eaoYu7Meta } from "/opt/build/repo/pages/account.vue?macro=true";
import { default as graphql_45api4D7ZuJeyDSMeta } from "/opt/build/repo/pages/graphql-api.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as new_45moodboardpCgrnCg7dwMeta } from "/opt/build/repo/pages/new-moodboard.vue?macro=true";
import { default as new_45presentationO7w2xADAvCMeta } from "/opt/build/repo/pages/new-presentation.vue?macro=true";
import { default as new_45quoteVMsbAgDTPbMeta } from "/opt/build/repo/pages/new-quote.vue?macro=true";
import { default as indexhr3vBNN83WMeta } from "/opt/build/repo/pages/presentations/index.vue?macro=true";
import { default as old_indexKozXpq3cG9Meta } from "/opt/build/repo/pages/presentations/old_index.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as _91moodboard_93qr48osmCFxMeta } from "/opt/build/repo/pages/projects/[project]/[moodboard].vue?macro=true";
import { default as _91presentation_93dxJpVrFAXkMeta } from "/opt/build/repo/pages/projects/[project]/[presentation].vue?macro=true";
import { default as _91room_931HUrRZjAteMeta } from "/opt/build/repo/pages/projects/[project]/[room].vue?macro=true";
import { default as indexBFYdFq64qpMeta } from "/opt/build/repo/pages/projects/[project]/index.vue?macro=true";
import { default as templategQcG19S9PnMeta } from "/opt/build/repo/pages/projects/[project]/moodboard/template.vue?macro=true";
import { default as _91quote_93i3TnKaRbHRMeta } from "/opt/build/repo/pages/projects/[project]/quotes/[quote].vue?macro=true";
import { default as newaAVWL8MtgKMeta } from "/opt/build/repo/pages/projects/[project]/quotes/new.vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/build/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "graphql-api",
    path: "/graphql-api",
    component: () => import("/opt/build/repo/pages/graphql-api.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "new-moodboard",
    path: "/crea-moodboard",
    component: () => import("/opt/build/repo/pages/new-moodboard.vue").then(m => m.default || m)
  },
  {
    name: "new-presentation",
    path: "/crea-presentazione",
    component: () => import("/opt/build/repo/pages/new-presentation.vue").then(m => m.default || m)
  },
  {
    name: "new-quote",
    path: "/crea-preventivo",
    component: () => import("/opt/build/repo/pages/new-quote.vue").then(m => m.default || m)
  },
  {
    name: "presentations",
    path: "/crea-moodboard",
    component: () => import("/opt/build/repo/pages/presentations/index.vue").then(m => m.default || m)
  },
  {
    name: "presentations-old_index",
    path: "/presentations/old_index",
    component: () => import("/opt/build/repo/pages/presentations/old_index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-moodboard",
    path: "/progetti/:project/:room/moodboard/:moodboard",
    component: () => import("/opt/build/repo/pages/projects/[project]/[moodboard].vue").then(m => m.default || m)
  },
  {
    name: "projects-project-presentation",
    path: "/progetti/:project/presentazioni/:presentation",
    component: () => import("/opt/build/repo/pages/projects/[project]/[presentation].vue").then(m => m.default || m)
  },
  {
    name: "projects-project-room",
    path: "/progetti/:project/:room",
    component: () => import("/opt/build/repo/pages/projects/[project]/[room].vue").then(m => m.default || m)
  },
  {
    name: "projects-project",
    path: "/progetti/:project",
    component: () => import("/opt/build/repo/pages/projects/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-moodboard-template",
    path: "/progetti/:project/:room/scegli-template",
    component: () => import("/opt/build/repo/pages/projects/[project]/moodboard/template.vue").then(m => m.default || m)
  },
  {
    name: "projects-project-quotes-quote",
    path: "/progetti/:project/preventivi/:quote",
    component: () => import("/opt/build/repo/pages/projects/[project]/quotes/[quote].vue").then(m => m.default || m)
  },
  {
    name: "projects-project-quotes-new",
    path: "/progetti/:project/preventivi/nuovo",
    component: () => import("/opt/build/repo/pages/projects/[project]/quotes/new.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/progetti",
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  }
]
<template>
  <Transition name="fade">
    <div
      v-if="loader.visible"
      class="fixed inset-0 z-50 flex flex-col items-center justify-center gap-6 bg-black-pure/70 backdrop-blur-md"
    >
      <div class="loader" />
      <div class="font-bold text-white text-md">{{ loader.label }}</div>
    </div>
  </Transition>
</template>

<script setup>
// Composables
const loader = useLoaderStore()
</script>

<style scoped>
.loader {
  width: 50px;
  aspect-ratio: 1.154;
  --_g: no-repeat radial-gradient(farthest-side, rgba(255, 242, 122, 1) 90%, rgba(255, 242, 122, 0));
  background: var(--_g) 50% 0, var(--_g) 0 100%, var(--_g) 100% 100%;
  background-size: 35% calc(35% * 1.154);
  animation: l16 1s infinite;
}
@keyframes l16 {
  50%,
  100% {
    background-position: 100% 100%, 50% 0, 0 100%;
  }
}
</style>

<template>
  <TransitionGroup
    name="slideInLeft"
    tag="div"
    class="fixed bottom-6 right-6 max-w-[calc(100%-3rem)] flex items-end flex-col gap-3"
  >
    <div
      v-for="(notify, n) in notificationStore.items"
      :key="`notify_${n}`"
      class="max-w-[27rem] px-6 py-4 text-black rounded-lg bg-accent flex gap-4 items-center shadow-lg"
    >
      <component :is="`svgo-${notify.icon}`" class="shrink-0" />
      <div>
        {{ notify.content }}
      </div>
    </div>
  </TransitionGroup>
</template>

<script setup>
// Composables
const notificationStore = useNotificationStore()
</script>

import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["crossSellSimpleProducts","crossSellVariableProducts","moodboardMeta","presentationMeta","productAcfMetaFields","productCategories","productTags","productTerms","projectMeta","simpleProduct","variableProduct","variant","variations","getBrand","getBrands","getCategorizedTextures","getMoodboard","getMoodboards","getOrder","getOrders","getPosts","getPresentation","getPresentations","getProduct","getProductCategories","getProductCategory","getProductVariants","getProducts","getProject","getProjects","getRoom","getRooms","getTextureCategories","getTextureCategory","getTextures","getUserData","getVariant","createMoodboard","createOrder","createPresentation","createProject","deleteMoodboard","deleteOrder","deleteOrderItems","deletePresentation","deleteProductCategory","deleteProject","LoginUser","logout","refreshAuthToken","resetPassword","sendPasswordResetEmail","updateMoodboard","updateOrder","updatePresentation","updateProductCategory","updateProject"]}
export const GqlLoginUser = (...params) => useGql()('LoginUser', ...params)
export const GqlCreateMoodboard = (...params) => useGql()('createMoodboard', ...params)
export const GqlCreateOrder = (...params) => useGql()('createOrder', ...params)
export const GqlCreatePresentation = (...params) => useGql()('createPresentation', ...params)
export const GqlCreateProject = (...params) => useGql()('createProject', ...params)
export const GqlDeleteMoodboard = (...params) => useGql()('deleteMoodboard', ...params)
export const GqlDeleteOrder = (...params) => useGql()('deleteOrder', ...params)
export const GqlDeleteOrderItems = (...params) => useGql()('deleteOrderItems', ...params)
export const GqlDeletePresentation = (...params) => useGql()('deletePresentation', ...params)
export const GqlDeleteProductCategory = (...params) => useGql()('deleteProductCategory', ...params)
export const GqlDeleteProject = (...params) => useGql()('deleteProject', ...params)
export const GqlGetBrand = (...params) => useGql()('getBrand', ...params)
export const GqlGetBrands = (...params) => useGql()('getBrands', ...params)
export const GqlGetCategorizedTextures = (...params) => useGql()('getCategorizedTextures', ...params)
export const GqlGetMoodboard = (...params) => useGql()('getMoodboard', ...params)
export const GqlGetMoodboards = (...params) => useGql()('getMoodboards', ...params)
export const GqlGetOrder = (...params) => useGql()('getOrder', ...params)
export const GqlGetOrders = (...params) => useGql()('getOrders', ...params)
export const GqlGetPosts = (...params) => useGql()('getPosts', ...params)
export const GqlGetPresentation = (...params) => useGql()('getPresentation', ...params)
export const GqlGetPresentations = (...params) => useGql()('getPresentations', ...params)
export const GqlGetProduct = (...params) => useGql()('getProduct', ...params)
export const GqlGetProductCategories = (...params) => useGql()('getProductCategories', ...params)
export const GqlGetProductCategory = (...params) => useGql()('getProductCategory', ...params)
export const GqlGetProductVariants = (...params) => useGql()('getProductVariants', ...params)
export const GqlGetProducts = (...params) => useGql()('getProducts', ...params)
export const GqlGetProject = (...params) => useGql()('getProject', ...params)
export const GqlGetProjects = (...params) => useGql()('getProjects', ...params)
export const GqlGetRoom = (...params) => useGql()('getRoom', ...params)
export const GqlGetRooms = (...params) => useGql()('getRooms', ...params)
export const GqlGetTextureCategories = (...params) => useGql()('getTextureCategories', ...params)
export const GqlGetTextureCategory = (...params) => useGql()('getTextureCategory', ...params)
export const GqlGetTextures = (...params) => useGql()('getTextures', ...params)
export const GqlGetUserData = (...params) => useGql()('getUserData', ...params)
export const GqlGetVariant = (...params) => useGql()('getVariant', ...params)
export const GqlLogout = (...params) => useGql()('logout', ...params)
export const GqlRefreshAuthToken = (...params) => useGql()('refreshAuthToken', ...params)
export const GqlResetPassword = (...params) => useGql()('resetPassword', ...params)
export const GqlSendPasswordResetEmail = (...params) => useGql()('sendPasswordResetEmail', ...params)
export const GqlUpdateMoodboard = (...params) => useGql()('updateMoodboard', ...params)
export const GqlUpdateOrder = (...params) => useGql()('updateOrder', ...params)
export const GqlUpdatePresentation = (...params) => useGql()('updatePresentation', ...params)
export const GqlUpdateProductCategory = (...params) => useGql()('updateProductCategory', ...params)
export const GqlUpdateProject = (...params) => useGql()('updateProject', ...params)
export default defineNuxtPlugin((nuxtApp) => {
  //const userToken = useCookie('userToken')
  const refreshToken = useCookie('refreshToken')

  /* nuxtApp.hook('gql:auth:init', ({ client, token }) => {
    console.log('gql:auth:init:', token.value)
    token.value = userToken.value
  }) */

  useGqlError(async (err) => {
    // Only log during development
    //console.log('useGqlError:', err)
    const internalServerError = err.gqlErrors.some(e => e.message.includes('Internal'))
    const operation = err.operationName
    const tokenExpired = err.gqlErrors.some(e => e.message.includes('id-token-expired'))
    const tokenRevoked = err.gqlErrors.some(e => e.message.includes('id-token-revoked'))
    const unauthorized = err.gqlErrors.some(e => e.message.includes('invalid-claims') || e.message.includes('insufficient-permission'))
    if((internalServerError || tokenExpired) && !['LoginUser', 'refreshAuthToken'].includes(operation)) {
      try {
        //console.log('refresh token', refreshToken.value)
        const authToken = await GqlRefreshAuthToken({ refreshToken: refreshToken.value })
        //console.log('refresh:', authToken.value)
        // Set token for GraphQL requests
        if(authToken.value) {
          useGqlToken({
            token: `${authToken.value}`,
            config: {
              type: 'Bearer',
              name: 'Authorization'
            }
          })
        }
      } catch(error) {
        console.error(error)
      }
    }
  })
})
<template>
  <AppLoader />
  <AppDeviceChecker />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <AppNotifications />
</template>

<script setup>
const router = useRouter()
const userStore = useUserStore()
const usersApi = useUsersApi()
const refreshToken = useCookie('refreshToken')
const userCookie = useCookie('userData')

// Watchers
router.beforeEach((to, from) => {
  if (refreshToken.value) {
    setUserDataFromCookie()
  }
})

// Methods
const setUserDataFromCookie = () => {
  // Se LOGGATO e lo user nello store è vuoto
  if (!Object.keys(userStore.userData).length) {
    if (userCookie.value) {
      userStore.setUserData({
        user: userCookie.value
      })
    } else {
      return navigateTo({ name: 'logout' })
    }
  }
}

onBeforeMount(async () => {
  if (refreshToken.value) {
    let user = userCookie?.value?.databaseId
      ? await usersApi.findOne(userCookie.value.databaseId)
      : null
    if (user) {
      userStore.setUserData({
        user: user
      })
    } else {
      navigateTo({ name: 'logout' })
    }
  }
})
</script>
